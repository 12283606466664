import React, { useCallback } from 'react';

import TuneIcon from '@material-ui/icons/Tune';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import { getStrategiesOverviewExportUrl, useSectionStrategies } from 'sdk';

import SortFilterDialog from 'pages/Pathways/components/Insights/components/SortFilterDialog';
import { openInNewTab } from 'utils/urls';

import ExportButton from 'components/ExportButton';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const FiltersBar = ({
  selectedSection,
  selectedGrade,
  selectedUnit,
  selectedLesson,
  filterSortParams,
  setFilterSortParams
}) => {
  const { data: strategiesData } = useSectionStrategies({
    sectionId: selectedSection?.id,
    params: {
      grade: selectedGrade?.name,
      unit: selectedUnit?.name,
      lesson: selectedLesson?.name
    }
  });

  const exportResults = useCallback(
    () =>
      openInNewTab(
        getStrategiesOverviewExportUrl({
          sectionId: selectedSection.id,
          grade: selectedGrade.name,
          unit: selectedUnit.name,
          lesson: selectedLesson.name
        })
      ),
    [selectedSection, selectedGrade, selectedUnit, selectedLesson]
  );

  const assignmentDate = strategiesData?.assignment_date;
  if (!assignmentDate) {
    return <Skeleton variant="rect" height={52} />;
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.title} variant="H-TEXT-3">
        Assignment Date:{' '}
        {moment(strategiesData['assignment_date']).format('MM/DD/YYYY')} 
      </Typography>
      <div className={styles.spacer}></div>
      <div className={styles.buttonsContainer}>
        <SortFilterDialog
          icon={<TuneIcon />}
          selectedSection={selectedSection}
          value={filterSortParams}
          onChange={setFilterSortParams}
          contentClass={styles.filtersPopoverContent}
        />
        <ExportButton handleClick={exportResults} />
      </div>
    </div>
  );
};

export default FiltersBar;
