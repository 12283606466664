import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { PATHWAYS_PROFILE_JOURNEY_URL } from 'config/urls';
import { pathwaysJourneyStart } from 'sdk';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { notifyError } from 'utils/notifications';

import PrivacyDialog from './components/PrivacyDialog';
import Button from 'components/Button';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const WelcomePathways = () => {
  const [isPrivacyDialogOpen, setPrivacyDialogOpen] = useState(false);

  const history = useHistory();
  const { user } = useContext(UserContext);

  const onPathwaysJourneyStarted = async () => {
    const { success } = await pathwaysJourneyStart();

    if (success) {
      history.push(PATHWAYS_PROFILE_JOURNEY_URL);
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
        {
          feature: 'pathways',
          view: 'learner profile',
          button: 'start'
        }
      );
    } else {
      notifyError('Could not start Pathways journey');
    }
  };

  const handleOpenPrivacyDialog = () => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'pathways',
        view: 'learner profile',
        button: 'what do we do with your results'
      }
    );
    setPrivacyDialogOpen(true);
  };

  const handleClosePrivacyDialog = () => {
    setPrivacyDialogOpen(false);
  };

  return (
    <div className={styles.container}>
      <Typography variant="H-TEXT-1" className={styles.welcomeTitle}>
        Welcome to Pathways!
      </Typography>
      <div className={styles.content}>
        Let's get to know your teaching goals and preferences to tailor your
        experience with personalized tips, resources, and progress tracking.
        Your journey to growth and success starts here.
      </div>
      <Typography variant="B-TEXT-3" className={styles.clickText}>
        Click below to begin!
      </Typography>
      <div className={styles.linkText}>
        <Typography variant="B-Text-2" onClick={handleOpenPrivacyDialog}>
          What do we do with the results?
        </Typography>
      </div>

      <div className={styles.bottomButton}>
        <Button
          variant="textAndIcon"
          className={styles.startButton}
          endIcon={<ArrowForwardIosOutlinedIcon className={styles.icon} />}
          onClick={onPathwaysJourneyStarted}
        >
          Start
        </Button>
      </div>

      {isPrivacyDialogOpen && (
        <PrivacyDialog onClose={handleClosePrivacyDialog} />
      )}
    </div>
  );
};

export default WelcomePathways;
