import { ChevronRight, Search } from 'lucide-react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { isNil } from 'lodash';

import { AssignmentDialogModes } from 'pages/Teachers/shared/AssignmentDialog';

import AssignmentCreateDialogContext from 'components/AssignmentCreateDialogContext';

import { useAcceleratedCurriculums, useCurriculums } from '../../sdk';
import styles from './style.module.scss';

const LEVELS = {
  CURRICULUM: 'curriculum',
  GRADE: 'grade',
  UNIT: 'unit',
  LESSON: 'lesson'
};

const CurriculumIndex = ({ isAcceleratedCurriculum }) => {
  const useCurriculumsHook = useMemo(() => {
    if (isAcceleratedCurriculum) {
      return useAcceleratedCurriculums;
    }
    return useCurriculums;
  }, [isAcceleratedCurriculum]);

  const [currentLevel, setCurrentLevel] = useState(LEVELS.CURRICULUM);
  const [parentId, setParentId] = useState(null);
  const [getLessons, setGetLessons] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({
    curriculum: false,
    grade: false
  });
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedItems, setSelectedItems] = useState({
    curriculum: null,
    grade: null,
    unit: null,
    lesson: null
  });

  const [cachedData, setCachedData] = useState({
    curriculum: [],
    grade: [],
    unit: [],
    lesson: []
  });

  const { open: openCreateAssignmentDialog } = useContext(
    AssignmentCreateDialogContext
  );

  const { data, isLoading } = useCurriculumsHook({
    parentId,
    getGrandchildren: getLessons
  });

  useEffect(() => {
    setCurrentLevel(LEVELS.CURRICULUM);
    setParentId(null);
    setGetLessons(false);
    setDropdownStates({
      curriculum: false,
      grade: false
    });
    setSearchTerm('');
    setSelectedItems({
      curriculum: null,
      grade: null,
      unit: null,
      lesson: null
    });
    setCachedData({
      curriculum: [],
      grade: [],
      unit: [],
      lesson: []
    });
  }, [isAcceleratedCurriculum]);

  useEffect(() => {
    if (!isLoading && data?.results) {
      setCachedData((prev) => ({
        ...prev,
        [currentLevel]: data.results
      }));
    }
  }, [data, isLoading, currentLevel, isAcceleratedCurriculum]);

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const getFilteredLessons = useCallback(
    (lessons) => {
      if (!searchTerm) return lessons;
      return lessons?.filter(
        (lesson) =>
          lesson.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lesson.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    [searchTerm]
  );

  const clearSubsequentStates = useCallback(
    (type) => {
      const levels = Object.values(LEVELS);
      const startIndex = levels.indexOf(type) + 1;

      const clearedSelections = { ...selectedItems };
      const clearedCache = { ...cachedData };

      levels.slice(startIndex).forEach((subsequentLevel) => {
        clearedSelections[subsequentLevel] = null;
        clearedCache[subsequentLevel] = [];
      });

      setGetLessons(false);
      setSelectedItems(clearedSelections);
      setCachedData(clearedCache);
      setSearchTerm('');
    },
    [selectedItems, cachedData]
  );

  const handleItemClick = useCallback(
    (item, type) => {
      if (!isNil(item.assignment_template)) {
        openCreateAssignmentDialog({
          mode: AssignmentDialogModes.CREATE_FROM_TEMPLATE,
          assignmentTemplateId: item.assignment_template.id,
          isAcceleratedCurriculum
        });
        return;
      }

      const newSelectedItems = { ...selectedItems };

      if (type === LEVELS.CURRICULUM) {
        Object.keys(newSelectedItems).forEach((key) => {
          if (key !== LEVELS.CURRICULUM) {
            newSelectedItems[key] = null;
          }
        });
        setCachedData((prev) => ({
          ...prev,
          grade: [],
          unit: [],
          lesson: []
        }));
      }

      if (selectedItems[type]?.id !== item.id) {
        newSelectedItems[type] = item;
        clearSubsequentStates(type);

        const nextLevel =
          Object.values(LEVELS)[Object.values(LEVELS).indexOf(type) + 1];
        setCurrentLevel(nextLevel);
        setParentId(item.id);
      }

      const nextLevel =
        Object.values(LEVELS)[Object.values(LEVELS).indexOf(type) + 1];
      setGetLessons(nextLevel === LEVELS.LESSON);

      setSelectedItems(newSelectedItems);
      setDropdownStates((currentStates) => ({
        ...currentStates,
        [type]: false
      }));
    },
    [
      selectedItems,
      clearSubsequentStates,
      openCreateAssignmentDialog,
      isAcceleratedCurriculum
    ]
  );

  const handleDropdownClick = useCallback(
    (type) => {
      setDropdownStates((currentStates) => {
        const newStates = { ...currentStates };
        newStates[type] = !currentStates[type];

        if (!currentStates[type]) {
          setCurrentLevel(type);
          if (type === LEVELS.GRADE && selectedItems.curriculum) {
            setParentId(selectedItems.curriculum.id);
          } else if (type === LEVELS.CURRICULUM) {
            setParentId(null);
          }
          setGetLessons(false);
        }

        return newStates;
      });
    },
    [selectedItems]
  );

  const showBlocks = selectedItems.grade && cachedData.unit.length > 0;

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <div className={styles.dropdownContainer}>
          <button
            className={styles.dropdown}
            onClick={() => handleDropdownClick(LEVELS.CURRICULUM)}
          >
            {selectedItems.curriculum?.title || 'Choose Curriculum'}
          </button>
          {dropdownStates.curriculum && (
            <div className={styles.dropdownMenu}>
              {isLoading ? (
                <div className={styles.loading}>Loading...</div>
              ) : (
                cachedData.curriculum.map((item) => (
                  <div
                    key={item.id}
                    className={styles.dropdownItem}
                    onClick={() => handleItemClick(item, LEVELS.CURRICULUM)}
                  >
                    {item.title}
                  </div>
                ))
              )}
            </div>
          )}
        </div>

        <div className={styles.dropdownContainer}>
          <button
            className={`${styles.dropdown} ${
              !selectedItems.curriculum ? styles.disabled : ''
            }`}
            onClick={() => handleDropdownClick(LEVELS.GRADE)}
            disabled={!selectedItems.curriculum}
          >
            {selectedItems.grade?.title || 'Choose Grade'}
          </button>
          {dropdownStates.grade && (
            <div className={styles.dropdownMenu}>
              {isLoading ? (
                <div className={styles.loading}>Loading...</div>
              ) : (
                cachedData.grade.map((item) => (
                  <div
                    key={item.id}
                    className={styles.dropdownItem}
                    onClick={() => handleItemClick(item, LEVELS.GRADE)}
                  >
                    {item.title}
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>

      {showBlocks && (
        <>
          <div className={styles.searchBar}>
            <input
              type="text"
              placeholder="Search Lessons"
              className={styles.searchInput}
              value={searchTerm}
              onChange={handleSearchChange}
              disabled={!selectedItems.unit}
            />
            <Search size={16} className={styles.searchIcon} />
          </div>

          <div className={styles.content}>
            <div className={styles.unitList}>
              {isLoading && currentLevel === LEVELS.UNIT ? (
                <div className={styles.loading}>Loading...</div>
              ) : (
                cachedData.unit.map((unit) => (
                  <div
                    key={unit.id}
                    className={`${styles.unit} ${
                      selectedItems.unit?.id === unit.id ? styles.selected : ''
                    }`}
                    onClick={() => handleItemClick(unit, LEVELS.UNIT)}
                  >
                    <div className={styles.unitTitle}>
                      <span>{unit.title}</span>
                      <span>{unit.description}</span>
                    </div>
                    {!isNil(unit.assignment_template) ? null : (
                      <ChevronRight
                        size={20}
                        className={`${styles.chevron} ${
                          selectedItems.unit?.id === unit.id
                            ? styles.selected
                            : ''
                        }`}
                      />
                    )}
                  </div>
                ))
              )}
            </div>

            <div className={styles.lessonList}>
              {selectedItems.unit &&
                (isLoading && currentLevel === LEVELS.LESSON ? (
                  <div className={styles.loading}>Loading...</div>
                ) : (
                  <>
                    {getFilteredLessons(cachedData.lesson).map((lesson) => (
                      <div
                        key={lesson.id}
                        className={`${styles.lesson} ${
                          selectedItems.lesson?.id === lesson.id
                            ? styles.selected
                            : ''
                        }`}
                        onClick={() => handleItemClick(lesson, LEVELS.LESSON)}
                      >
                        <div className={styles.lessonTitle}>
                          <div>{lesson.title}</div>
                          <div className={styles.lessonSubtitle}>
                            {lesson.description}
                          </div>
                        </div>
                      </div>
                    ))}
                    {!isLoading &&
                      getFilteredLessons(cachedData.lesson).length === 0 && (
                        <div className={styles.noResults}>
                          No lessons found matching your search.
                        </div>
                      )}
                  </>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CurriculumIndex;
