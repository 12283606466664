import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { PATHWAYS_TODAY_LESSON_URL } from 'config/urls';
import { isNull } from 'lodash';
import { miniLessonDisLike, miniLessonLike } from 'sdk';

import InfoPanel from 'pages/Pathways/components/PathwaysDailyProgress/components/InfoPanel';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { notifyErrors } from 'utils/notifications';

import Button from 'components/Button';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as ReadIcon } from './assets/read.svg';

const HEADER_HEIGHT = 48;

const useStyles = makeStyles({
  headerStyle: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    position: 'relative'
  }),
  headerContent: (theme) => ({
    position: 'absolute',
    top: -16,
    lineHeight: `${theme.headerHeight + 28}px`,
    fontWeight: 600,
    fontSize: '17px',
    letterSpacing: '0.15px'
  }),
  contentStyle: {
    fontWeight: 700,
    color: '#CC2364',
    textAlign: 'center',
    textTransform: 'none',
    fontSize: 20,
    margin: '0 50px'
  },
  contentLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  readButton: (theme) => ({
    color: '#5A608D',
    backgroundColor: '#fff',
    paddingLeft: 12,
    paddingRight: 8,
    '&:hover': {
      backgroundColor: theme.primaryColor
    },
    '& p': {
      fontSize: 11
    }
  }),
  likeButton: (theme) => ({
    color: '#d9d9d9',
    backgroundColor: '#fff',
    fontSize: 11,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    paddingLeft: 12,
    paddingRight: 8,
    '& p': {
      textTransform: 'none',
      fontSize: 11
    },
    '&:hover': {
      backgroundColor: theme.primaryColor
    }
  }),
  thumbDownButton: (theme) => ({
    color: '#D9D9D9',
    backgroundColor: '#fff',
    height: 40,
    width: 40,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: '2px solid #D9D9D9',
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20
    },
    '&:hover': {
      backgroundColor: theme.primaryColor
    }
  }),
  readButtonArrow: {
    color: '#F05B94',
    scale: 0.65
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'center'
  },
  thumbsButtonLayout: {
    display: 'flex',
    flexDirection: 'row'
  }
});

const MiniLesson = ({ miniLesson, refetchMiniLesson }) => {
  const panelTheme = {
    primaryColor: '#E5E7FF',
    secondaryColor: '#292E52',
    accentColor: '#F6F5FF',
    headerHeight: HEADER_HEIGHT
  };
  const styles = useStyles(panelTheme);
  const user = useContext(UserContext);

  const lessonLike = async () => {
    const { success, errors } = await miniLessonLike({ id: miniLesson.id });

    if (success) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
        {
          feature: 'pathways',
          view: 'daily progress lesson',
          button: 'like',
          lesson: miniLesson.id
        }
      );
      refetchMiniLesson();
    } else {
      notifyErrors(errors);
    }
  };

  const lessonDisLike = async () => {
    const { success, errors } = await miniLessonDisLike({ id: miniLesson.id });

    if (success) {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
        {
          feature: 'pathways',
          view: 'daily progress lesson',
          button: 'dislike',
          lesson: miniLesson.id
        }
      );
      refetchMiniLesson();
    } else {
      notifyErrors(errors);
    }
  };

  return (
    <div className={styles.infoPanelContainer}>
      <InfoPanel
        theme={panelTheme}
        header={
          <div className={styles.headerStyle}>
            <div className={styles.headerContent}>Today's Lesson</div>
          </div>
        }
        content={
          <>
            {(isNull(miniLesson) || miniLesson?.id === null) && (
              <Typography variant="H-TEXT-1" className={styles.contentStyle}>
                No lesson today — take a moment to recharge and reflect on your
                progress!
              </Typography>
            )}

            {!isNull(miniLesson) && miniLesson?.id !== null && (
              <div className={styles.contentLayout}>
                <Typography variant="H-TEXT-1" className={styles.contentStyle}>
                  {miniLesson?.title}
                </Typography>
                <div className={styles.buttonsRow}>
                  <Link
                    to={{
                      pathname: PATHWAYS_TODAY_LESSON_URL,
                      state: { lesson: miniLesson }
                    }}
                    className={styles.noUnderline}
                  >
                    <Button
                      variant="textAndIcon"
                      className={styles.readButton}
                      startIcon={<ReadIcon />}
                      endIcon={
                        <ArrowForwardIosOutlinedIcon
                          className={styles.readButtonArrow}
                        />
                      }
                      onClick={() => {
                        trackMixpanelEvent(
                          user.id,
                          `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
                          {
                            feature: 'pathways',
                            view: 'daily progress lesson',
                            button: 'click',
                            lesson: miniLesson.id
                          }
                        );
                      }}
                    >
                      Read Lesson
                    </Button>
                  </Link>
                  <div className={styles.thumbsButtonLayout}>
                    <Button
                      variant="textAndIcon"
                      className={styles.likeButton}
                      startIcon={<ThumbUpIcon />}
                      onClick={lessonLike}
                      disabled={miniLesson?.is_liked}
                      style={miniLesson?.is_liked ? { color: '#d03673' } : {}}
                    >
                      {miniLesson?.user_likes} Teachers liked this
                    </Button>
                    <IconButton
                      size="small"
                      className={styles.thumbDownButton}
                      onClick={lessonDisLike}
                      disabled={miniLesson?.is_disliked}
                      style={
                        miniLesson?.is_disliked ? { color: '#d03673' } : {}
                      }
                    >
                      <ThumbDownIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

export default MiniLesson;
