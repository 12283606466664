import React, { useState } from 'react';

import { isEmpty, isNull } from 'lodash';

import NextStepsTable from 'pages/Teachers/InsightsV2/components/NextStepsTable';
import StudentSummaryDialog from 'pages/Teachers/InsightsV2/components/StudentSummaryDialog';
import { STUDENT_WORK_INTERCOM_TARGET } from 'pages/Teachers/InsightsV2/constants';
import { colors } from 'theme/palette';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const MasteryLevelDetail = ({
  students,
  nextSteps,
  exemplarUrls,
  refetchOverview,
  trackStudentWorkDialogClickEvent,
  trackNextStepCompleteEvent
}) => {
  const [summaryDialogIsOpen, setSummaryDialogIsOpen] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [workTagImageId, setWorkTagImageId] = useState(null);

  const handleStudentClick = ({ name, workTagImageId }) => {
    trackStudentWorkDialogClickEvent();

    setStudentName(name);
    setWorkTagImageId(workTagImageId);
    setSummaryDialogIsOpen(true);
  };

  const handleNextIconClick = () => {
    if (isNull(workTagImageId) || isEmpty(studentName)) return;

    const currentIndex = students.findIndex(
      (item) => item.work_tag_image_id === workTagImageId
    );
    const nextIndex = (currentIndex + 1) % students.length;

    const { name, work_tag_image_id } = students[nextIndex];
    setStudentName(name);
    setWorkTagImageId(work_tag_image_id);
  };
  const handlePreviousIconClick = () => {
    if (isNull(workTagImageId) || isEmpty(studentName)) return;

    const currentIndex = students.findIndex(
      (item) => item.work_tag_image_id === workTagImageId
    );

    const previousIndex =
      (currentIndex - 1 + students.length) % students.length;

    const { name, work_tag_image_id } = students[previousIndex];
    setStudentName(name);
    setWorkTagImageId(work_tag_image_id);
  };

  return (
    <div className={styles.details}>
      <div
        data-intercom-target={STUDENT_WORK_INTERCOM_TARGET}
        className={styles.students}
      >
        <div className={styles.studentsContainer}>
          {students.map(({ name, work_tag_image_id }) => (
            <div
              key={name}
              className={styles.studentNameContainer}
              onClick={() => {
                handleStudentClick({
                  name: name,
                  workTagImageId: work_tag_image_id
                });
              }}
            >
              <Typography
                variant="B-TEXT-4"
                className={styles.studentName}
                color={colors.blue10}
              >
                {name}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      {!isEmpty(nextSteps) && (
        <div className={styles.nextSteps}>
          <NextStepsTable
            nextSteps={nextSteps}
            refetchOverview={refetchOverview}
            exemplarUrls={exemplarUrls}
            trackNextStepCompleteEvent={trackNextStepCompleteEvent}
          />
        </div>
      )}
      {summaryDialogIsOpen && (
        <StudentSummaryDialog
          studentName={studentName}
          workTagImageId={workTagImageId}
          allStudentsData={students}
          refetchOverview={refetchOverview}
          isSummaryDialogIsOpen={summaryDialogIsOpen}
          handleNextIconClick={handleNextIconClick}
          handlePreviousIconClick={handlePreviousIconClick}
          onClose={() => {
            setSummaryDialogIsOpen(false);
            setWorkTagImageId(null);
            setStudentName('');
          }}
        />
      )}
    </div>
  );
};

export default MasteryLevelDetail;
