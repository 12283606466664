import React, { useContext, useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useWorkTagImageMasteryLevelData } from 'sdk';

import LoadingSkeleton from 'pages/Teachers/InsightsV2/components/LoadingSkeleton';
import MisconceptionMasteryLevel from 'pages/Teachers/InsightsV2/components/MisconceptionMasteryLevel';
import OverallResponseMasteryLevel from 'pages/Teachers/InsightsV2/components/OverallResponseMasteryLevel';
import { trackMixpanelPageView } from 'utils/integrations/mixpanel';

import Dialog from 'components/Dialog';
import Image from 'components/Image';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const StudentSummaryDialog = ({
  studentName,
  workTagImageId,
  refetchOverview,
  isSummaryDialogIsOpen,
  handleNextIconClick,
  handlePreviousIconClick,
  onClose
}) => {
  const user = useContext(UserContext);

  useEffect(() => {
    trackMixpanelPageView(user.id, {
      feature: 'insights',
      view: 'student work'
    });
  }, [user]);

  const {
    data,
    isLoading,
    refetch: refetchMasteryLevel
  } = useWorkTagImageMasteryLevelData({
    workTagImageId
  });

  return (
    <div className={styles.dialogContainer}>
      <Dialog
        open={isSummaryDialogIsOpen}
        onClose={onClose}
        className={styles.dialog}
        classes={{ paper: styles.paperRoot }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.headingContainer}>
          <IconButton onClick={handlePreviousIconClick}>
            <SvgIcon className={styles.svgIcon} component={ChevronLeftIcon} />
          </IconButton>
          <Typography className={styles.studentName} variant="H-TEXT-1">
            {studentName}
          </Typography>
          <IconButton onClick={handleNextIconClick}>
            <SvgIcon className={styles.svgIcon} component={ChevronRightIcon} />
          </IconButton>
        </div>

        <div className={styles.dialogContent}>
          <div className={styles.leftCard}>
            <div className={styles.imageContainer}>
              {!isLoading && (
                <Image src={data.work_tag_image_url} className={styles.image} />
              )}
            </div>
          </div>
          <div className={styles.rightCard}>
            {isLoading && <LoadingSkeleton />}
            {!isLoading && (
              <>
                <OverallResponseMasteryLevel
                  workTagImageId={workTagImageId}
                  masteryLevel={data.overall_response_mastery_level}
                  refetchOverview={refetchOverview}
                  refetchMasteryLevel={refetchMasteryLevel}
                />
                <MisconceptionMasteryLevel
                  workTagImageId={workTagImageId}
                  masteryLevel={data.misconception_mastery_level}
                  refetchOverview={refetchOverview}
                  refetchMasteryLevel={refetchMasteryLevel}
                />
              </>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default StudentSummaryDialog;
