import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SvgIcon from '@material-ui/core/SvgIcon';
import { TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL } from 'config/urls';
import {
  getInsightsOverviewExportURL,
  useMisconceptionsOverview,
  useOverallResponseOverview,
  useTrackerInstanceSectionCheckPreannotationsStatus,
  useTrackerInstanceSectionDetail
} from 'sdk';

import MisconceptionsOverview from 'pages/Teachers/InsightsV2/components/MisconceptionsOverview';
import OverallResponseOverview from 'pages/Teachers/InsightsV2/components/OverallResponseOverview';
import {
  MISCONCEPTIONS_TAB,
  OVERALL_RESPONSE_TAB
} from 'pages/Teachers/InsightsV2/constants';
import { useNavbar } from 'pages/Teachers/shared/TeacherPageLayout';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';
import { openInNewTab } from 'utils/urls';

import TabSwitcher from './components/TabSwitcher';
import ExportButton from 'components/ExportButton';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as MainIcon } from './assets/edlightMain.svg';
import styles from './styles.module.scss';

const InsightsV2 = ({ history }) => {
  const user = useContext(UserContext);

  const { trackerInstanceSectionId } = useParams();

  const [activeTab, setActiveTab] = useState(OVERALL_RESPONSE_TAB);
  const [
    shouldFetchOverallResponseDataOneMoreTime,
    setShouldFetchOverallResponseDataOneMoreTime
  ] = useState(false);

  const [
    shouldFetchMisconceptionsDataOneMoreTime,
    setShouldFetchMisconceptionsDataOneMoreTime
  ] = useState(false);

  const { data: trackerInstanceSection } = useTrackerInstanceSectionDetail({
    trackerInstanceSectionId
  });

  const {
    data: assignmentCheckPreannotationsStatus,
    isLoading: isAssignmentCheckPreannotationsStatusLoading,
    refetch: refetchAssignmentCheckPreannotationsStatus
  } = useTrackerInstanceSectionCheckPreannotationsStatus({
    trackerInstanceSectionId
  });

  const {
    data: overviewData,
    isLoading: isOverviewDataLoading,
    refetch: refetchOverview
  } = useOverallResponseOverview({
    trackerInstanceSectionId
  });

  const {
    data: misconceptionsData,
    isLoading: isMisconceptionsDataLoading,
    refetch: refetchMisconceptionsData
  } = useMisconceptionsOverview({
    trackerInstanceSectionId
  });

  const handleTitleClick = useCallback(
    () => history.push(TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL),
    [history]
  );

  useNavbar({
    title: '',
    handleTitleClick,
    sticky: true,
    onBackButtonClick: handleTitleClick
  });

  const exportResults = useCallback(() => {
    openInNewTab(getInsightsOverviewExportURL({ trackerInstanceSectionId }));
  }, [trackerInstanceSectionId]);

  const handleExportClick = useCallback(() => {
    trackMixpanelEvent(
      user.id,
      `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
      {
        feature: 'insights',
        button: 'export'
      }
    );

    exportResults();
  }, [user, exportResults]);

  useEffect(() => {
    if (
      !isAssignmentCheckPreannotationsStatusLoading &&
      assignmentCheckPreannotationsStatus &&
      !assignmentCheckPreannotationsStatus['is_fully_preannotated']
    ) {
      const interval = setInterval(
        refetchAssignmentCheckPreannotationsStatus,
        5000
      );
      return () => clearInterval(interval);
    } else {
      setTimeout(
        () => setShouldFetchOverallResponseDataOneMoreTime(true),
        5000
      ); // Single fetch trigger
      setTimeout(() => setShouldFetchMisconceptionsDataOneMoreTime(true), 5000); // Single fetch trigger
    }
  }, [
    assignmentCheckPreannotationsStatus,
    refetchAssignmentCheckPreannotationsStatus,
    isAssignmentCheckPreannotationsStatusLoading
  ]);

  useEffect(() => {
    if (
      assignmentCheckPreannotationsStatus &&
      (!assignmentCheckPreannotationsStatus['is_fully_preannotated'] ||
        shouldFetchOverallResponseDataOneMoreTime) &&
      activeTab === OVERALL_RESPONSE_TAB
    ) {
      const interval = setInterval(() => {
        refetchOverview();
        if (shouldFetchOverallResponseDataOneMoreTime) {
          setShouldFetchOverallResponseDataOneMoreTime(false);
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [
    assignmentCheckPreannotationsStatus,
    refetchOverview,
    shouldFetchOverallResponseDataOneMoreTime,
    activeTab
  ]);

  useEffect(() => {
    if (
      assignmentCheckPreannotationsStatus &&
      (!assignmentCheckPreannotationsStatus['is_fully_preannotated'] ||
        shouldFetchMisconceptionsDataOneMoreTime) &&
      activeTab === MISCONCEPTIONS_TAB
    ) {
      const interval = setInterval(() => {
        refetchMisconceptionsData();
        if (shouldFetchMisconceptionsDataOneMoreTime) {
          setShouldFetchMisconceptionsDataOneMoreTime(false);
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [
    assignmentCheckPreannotationsStatus,
    refetchMisconceptionsData,
    shouldFetchMisconceptionsDataOneMoreTime,
    activeTab
  ]);

  const handleTabSwitch = useCallback(
    (tab) => {
      if (tab === OVERALL_RESPONSE_TAB) {
        refetchOverview();
      } else {
        refetchMisconceptionsData();
      }
      setActiveTab(tab);
    },
    [refetchMisconceptionsData, refetchOverview]
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <Typography variant="H-TEXT-1" className={styles.headerText}>
          {trackerInstanceSection?.tracker_name} -{' '}
          {trackerInstanceSection?.section_name}
        </Typography>
        <ExportButton handleClick={handleExportClick} />
      </div>
      <div>
        {trackerInstanceSection?.standards.map((standard, index) => (
          <div key={index} className={styles.standardsContainer}>
            <SvgIcon component={MainIcon} />
            <span>
              {standard['code']} - {standard['short_description']}
            </span>
          </div>
        ))}
      </div>
      <TabSwitcher activeTab={activeTab} onTabSwitch={handleTabSwitch} />

      <div className={styles.detailContainer}>
        {activeTab === OVERALL_RESPONSE_TAB && (
          <OverallResponseOverview
            data={overviewData}
            isLoading={isOverviewDataLoading}
            refetchOverview={refetchOverview}
          />
        )}
        {activeTab === MISCONCEPTIONS_TAB && (
          <MisconceptionsOverview
            data={misconceptionsData}
            isLoading={isMisconceptionsDataLoading}
            refetchOverview={refetchMisconceptionsData}
          />
        )}
      </div>
    </div>
  );
};

export default InsightsV2;
