import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';

import {
  TEACHERS_BULK_UPLOAD_NAME_REGION_SELECT_URL,
  TEACHERS_BULK_UPLOAD_SUBMISSIONS_MATCH_URL
} from 'config/urls';
import _ from 'lodash';
import {
  bulkUploadAssignmentAssign,
  bulkUploadAutomaticNameRegion,
  bulkUploadFirstAssignmentItems,
  bulkUploadPagesConfirm,
  bulkUploadPagesConfirmCancel,
  useAssignmentsNamesList,
  useBulkUploadDetail
} from 'sdk';

import Layout from 'pages/Teachers/BulkUpload/components/Layout';
import { useBulkUploadStatusRedirect } from 'pages/Teachers/BulkUpload/hooks';
import { useNavbar } from 'pages/Teachers/shared/TeacherPageLayout';
import { notifyErrors } from 'utils/notifications';
import { reverse, stringifyParams } from 'utils/urls';

import AssignAssignment from './components/AssignAssignment';
import ConfirmPages from './components/ConfirmPages';
import AssignmentDialogContext from 'components/AssignmentDialogContext';

const ConsolidatedAssignAndConfirm = ({ match }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const { setIsAssignmentDialogOpened } = useContext(AssignmentDialogContext);
  const [rotationCount, setRotationCount] = useState(0);
  const [firstAssignmentItems, setFirstAssignmentItems] = useState(null);
  const [focusedPageNumber, setFocusedPageNumber] = useState(1);
  const [focusedImage, setFocusedImage] = useState(null);

  useNavbar({ title: 'CAPTURE WORK' });

  const { data: assignmentsNames } = useAssignmentsNamesList({
    data: { include_prior_years: true }
  });

  const history = useHistory();

  const bulkUploadId = _.get(match, 'params.bulkUploadId');

  const {
    data: bulkUpload,
    isLoading: isFetchingBulkUpload,
    refetch: refectchBulkUploadDetails
  } = useBulkUploadDetail({ bulkUploadId });

  useBulkUploadStatusRedirect({
    bulkUpload,
    isLoading: isFetchingBulkUpload
  });

  const refetchAssignmentItems = useCallback(async () => {
    if (bulkUpload?.assignment) {
      const { data: assignmentItems } = await bulkUploadFirstAssignmentItems({
        bulkUploadId
      });
      setFirstAssignmentItems(assignmentItems);
    }
  }, [bulkUpload?.assignment, bulkUploadId]);

  useEffect(() => {
    async function fetchAssignmentData() {
      if (bulkUpload?.assignment) {
        const { data: assignmentItems } = await bulkUploadFirstAssignmentItems({
          bulkUploadId
        });
        setFirstAssignmentItems(assignmentItems);
      }
    }
    fetchAssignmentData();
  }, [bulkUpload, bulkUploadId, setFirstAssignmentItems]);

  const totalPageNumbers = firstAssignmentItems?.length;

  const shouldPoll = useMemo(() => {
    if (!firstAssignmentItems) {
      return false;
    } else {
      return _.some(firstAssignmentItems, (item) => _.isNil(item.image));
    }
  }, [firstAssignmentItems]);

  useEffect(() => {
    setShowLoader(shouldPoll);
    if (shouldPoll) {
      const interval = setInterval(() => {
        refetchAssignmentItems();
        refectchBulkUploadDetails();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [shouldPoll, refetchAssignmentItems, refectchBulkUploadDetails]);

  useEffect(() => {
    if (firstAssignmentItems) {
      setFocusedImage(firstAssignmentItems[focusedPageNumber - 1]);
    }
  }, [firstAssignmentItems, setFocusedImage, focusedPageNumber]);

  const removeAssociatedAssignment = async () => {
    if (bulkUpload?.assignment) {
      setSelectedAssignment(null);
      setFocusedPageNumber(1);
      const { success, errors } = await bulkUploadPagesConfirmCancel({
        bulkUploadId
      });

      if (!success) {
        notifyErrors(errors);
      }
    }
  };

  const handleAssignmentSelect = async (value) => {
    setShowLoader(true);

    if (!value) {
      setFirstAssignmentItems(null);
    } else if (bulkUpload?.assignment?.id !== value?.tracker_id) {
      await removeAssociatedAssignment();
      const { success, errors } = await bulkUploadAssignmentAssign({
        bulkUploadId,
        data: { assignment: value.tracker_id }
      });

      if (!success) {
        notifyErrors(errors);
      }
    }
    setSelectedAssignment(value);
    refectchBulkUploadDetails();

    setShowLoader(false);
  };

  const BULK_UPLOAD_NAME_REGION_SELECT_PAGE_SKIP =
    process.env.REACT_APP_BULK_UPLOAD_NAME_REGION_SELECT_PAGE_SKIP === 'true';

  const handleCreateClick = async () => {
    await removeAssociatedAssignment();
    setFirstAssignmentItems(null);
    refectchBulkUploadDetails();
    history.replace({
      pathname: history.pathname,
      search: stringifyParams({
        bulkUploadId,
        is_bulk_upload: true
      })
    });
    setIsAssignmentDialogOpened(true);
  };

  const handleNextClick = async ({ trackerId }) => {
    setShowLoader(true);

    const { success, errors } = await bulkUploadPagesConfirm({
      bulkUploadId,
      rotationCount
    });

    if (success) {
      if (BULK_UPLOAD_NAME_REGION_SELECT_PAGE_SKIP) {
        const { success: autoNameRegionSuccess, errors: autoNameRegionErrors } =
          await bulkUploadAutomaticNameRegion({
            bulkUploadId,
            rotationCount
          });
        if (autoNameRegionSuccess) {
          history.push(
            reverse(TEACHERS_BULK_UPLOAD_SUBMISSIONS_MATCH_URL, {
              bulkUploadId: bulkUploadId
            })
          );
        } else {
          notifyErrors(autoNameRegionErrors);
        }
      } else {
        history.push(
          reverse(TEACHERS_BULK_UPLOAD_NAME_REGION_SELECT_URL, {
            bulkUploadId
          })
        );
      }
    } else {
      notifyErrors(errors);
    }

    setShowLoader(false);
  };

  return (
    <>
      <Layout
        currentStepIndex={1}
        showLoader={showLoader}
        nextButtonAction={handleNextClick}
        isNextButtonDisabled={_.isNil(firstAssignmentItems) || showLoader}
      >
        <AssignAssignment
          selectedAssignment={selectedAssignment}
          assignmentsNames={assignmentsNames}
          handleAssignmentSelect={handleAssignmentSelect}
          handleCreateClick={handleCreateClick}
        />
        {bulkUpload?.assignment && firstAssignmentItems && !showLoader && (
          <ConfirmPages
            bulkUpload={bulkUpload}
            focusedImage={focusedImage}
            rotationCount={rotationCount}
            focusedPageNumber={focusedPageNumber}
            setFocusedPageNumber={setFocusedPageNumber}
            totalPageNumbers={totalPageNumbers}
            setRotationCount={setRotationCount}
          />
        )}
      </Layout>
    </>
  );
};

export default ConsolidatedAssignAndConfirm;
