import { QRCodeSVG } from 'qrcode.react';
import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import { APPLE_APP_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from 'config/constants';
import {
  MOBILE_APP_URL,
  TEACHERS_BULK_UPLOAD_ASSIGN_AND_CONFIRMATION_URL
} from 'config/urls';
import { ReactComponent as AppleStoreButton } from 'images/apple-store-download.svg';
import { ReactComponent as UploadIcon } from 'images/bulk-upload.svg';
import { ReactComponent as GooglePlayButton } from 'images/google-play-download.svg';
import _ from 'lodash';
import { createBulkUpload, createBulkUploadMultiplePDFs } from 'sdk';

import Layout from 'pages/Teachers/BulkUpload/components/Layout';
import AttachmentUploadOverlay from 'pages/Teachers/shared/AttachmentUploadOverlay';
import { useNavbar } from 'pages/Teachers/shared/TeacherPageLayout';
import { colors } from 'theme/palette';
import { uploadFile } from 'utils/files';
import { notifyError, notifyErrors } from 'utils/notifications';
import { reverse } from 'utils/urls';

import FileLine from './components/FileLine';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const WorkUploadWithQR = () => {
  const [fileThatIsBeingUploaded, setFileThatIsBeingUploaded] = useState(null);
  const [filesThatHaveBeenUploaded, setFilesThatHaveBeenUploaded] = useState(
    []
  );
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useNavbar({ title: 'CAPTURE WORK' });

  const uploading = !_.isNil(fileThatIsBeingUploaded);
  const nextButtonDisabled =
    filesThatHaveBeenUploaded.length === 0 || uploading;
  const allowMultipleFiles =
    process.env.REACT_APP_BULK_UPLOAD_ALLOW_FOR_MULTIPLE_FILES === 'true';
  const history = useHistory();

  const { user } = useContext(UserContext);

  const onDropMultipleFiles = async (files) => {
    for (const file of files) {
      if (!_.isNil(file)) {
        setFileThatIsBeingUploaded(file);

        const uploadedFileId = await uploadFile({
          blob: file,
          name: file.name,
          maxSize: {
            sizeInBytes: 100 * 1_048_576,
            sizeAsString: '100MB'
          },
          trackMixpanelEventOnFailure: true
        });

        if (_.isNil(uploadedFileId)) {
          setFileThatIsBeingUploaded(null);
        } else {
          file.uploadedFileId = uploadedFileId;
          setFilesThatHaveBeenUploaded((prev) => [...prev, file]);
          setUploadedFileIds((prev) => [...prev, uploadedFileId]);
        }
      }
    }
    setFileThatIsBeingUploaded(null);
  };

  const handleDeleteFile = (file) => {
    const uploadedFileId = file?.uploadedFileId;
    setFilesThatHaveBeenUploaded((l) =>
      l.filter((file) => file.uploadedFileId !== uploadedFileId)
    );
    setUploadedFileIds((l) => l.filter((id) => id !== uploadedFileId));
  };

  const onDropSingleFile = async (files) => {
    const file = files[0];

    if (!_.isNil(file)) {
      setFileThatIsBeingUploaded(file);

      const uploadedFileId = await uploadFile({
        blob: file,
        name: file.name,
        maxSize: {
          sizeInBytes: 100 * 1_048_576,
          sizeAsString: '100MB'
        },
        trackMixpanelEventOnFailure: true
      });

      if (_.isNil(uploadedFileId)) {
        setFileThatIsBeingUploaded(null);
        return;
      }

      const data = { pdf: uploadedFileId };

      const {
        success,
        data: bulkUpload,
        errors
      } = await createBulkUpload({
        data
      });

      if (success) {
        history.push(
          reverse(TEACHERS_BULK_UPLOAD_ASSIGN_AND_CONFIRMATION_URL, {
            bulkUploadId: bulkUpload.id
          })
        );
      } else {
        notifyErrors(errors);
        setFileThatIsBeingUploaded(null);
      }
    }
  };

  const handleNextClick = async ({ trackerId }) => {
    setShowLoader(true);
    const data = { pdfs: uploadedFileIds };

    const {
      success,
      data: bulkUpload,
      errors
    } = await createBulkUploadMultiplePDFs({
      data
    });

    if (success) {
      history.push(
        reverse(TEACHERS_BULK_UPLOAD_ASSIGN_AND_CONFIRMATION_URL, {
          bulkUploadId: bulkUpload.id
        })
      );
      setShowLoader(false);
    } else {
      notifyErrors(errors);
    }
  };

  const onDropRejected = (files) => {
    return _.forEach(files, () => notifyError('Unsupported file type.'));
  };

  let dropzoneProps, layoutProps;

  if (allowMultipleFiles) {
    dropzoneProps = {
      onDrop: onDropMultipleFiles,
      accept: '.pdf',
      onDropRejected,
      multiple: true // Enable multiple file uploads
    };
    layoutProps = {
      currentStepIndex: 0,
      nextButtonAction: handleNextClick,
      isNextButtonDisabled: uploading || nextButtonDisabled || showLoader,
      showLoader: showLoader
    };
  } else {
    dropzoneProps = {
      onDrop: onDropSingleFile,
      accept: '.pdf',
      onDropRejected,
      multiple: false // disable multiple file uploads
    };
    layoutProps = {
      currentStepIndex: 0
    };
  }

  const { getRootProps, getInputProps, isDragActive, open } =
    useDropzone(dropzoneProps);

  return (
    <Layout {...layoutProps}>
      <div
        {...getRootProps({
          onClick: (event) => event.stopPropagation()
        })}
      >
        <input {...getInputProps()} />
        {isDragActive && <AttachmentUploadOverlay />}
        <div className={styles.container}>
          <>
            <div className={styles.uploadContainer}>
              <div className={styles.upload} onClick={open}>
                <div className={styles.uploadInnerContainer}>
                  <UploadIcon
                    className={styles.uploadIcon}
                    color={colors.blue2}
                  />
                  <Typography
                    align="left"
                    variant="B-Text-2"
                    className={styles.uploadText}
                    color={colors.blue3}
                  >
                    Upload PDF of Scanned work by choosing files, dragging them
                    here, or emailing to work@edlight.com
                  </Typography>
                  <div className={styles.checkText}>
                    <CheckIcon className={styles.checkIcon} />
                    <Typography
                      align="left"
                      variant="B-Text-4"
                      className={styles.uploadText}
                    >
                      Upload work for one assignment at a time
                    </Typography>
                  </div>
                  <div className={styles.checkText}>
                    <CheckIcon className={styles.checkIcon} />
                    <Typography
                      align="left"
                      variant="B-Text-4"
                      className={styles.uploadText}
                    >
                      Arrange students' pages in the correct order
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.qrCode}>
                <QRCodeSVG
                  width={187}
                  height={187}
                  value={`${window.location.origin}${MOBILE_APP_URL}`}
                />
                <Typography
                  align="left"
                  variant="B-Text-2"
                  className={styles.description}
                  color={colors.blue3}
                >
                  Or scan student work using EdLight's phone/tablet app
                </Typography>
                <Typography align="left" variant="B-Text-4">
                  Download the EdLight app now from the Apple Store or Google
                  Play
                </Typography>
                <div className={styles.appStoreLinks}>
                  <a
                    href={GOOGLE_PLAY_STORE_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GooglePlayButton className={styles.appStoreButton} />
                  </a>
                  <a
                    href={APPLE_APP_STORE_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AppleStoreButton className={styles.appStoreButton} />
                  </a>
                </div>
              </div>
            </div>
          </>
          {allowMultipleFiles &&
            filesThatHaveBeenUploaded.map((file, index) => {
              return (
                <FileLine
                  key={`file-${index}`}
                  file={file}
                  user={user}
                  showProgress={false}
                  handleDeleteFile={handleDeleteFile}
                />
              );
            })}
          {uploading && <FileLine file={fileThatIsBeingUploaded} user={user} />}
        </div>
      </div>
    </Layout>
  );
};

export default WorkUploadWithQR;
