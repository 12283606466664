import React, { useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { first, get, isEmpty } from 'lodash';
import { useStudentLearningTrendsData } from 'sdk';

import StudentWorkViewDialog from 'components/StudentWorkViewDialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const LearningTrends = ({ studentId, selectedYear, selectedMonthsBack }) => {
  const { data: learningTrendsData, isLoading } = useStudentLearningTrendsData({
    studentId,
    params: {
      year: get(selectedYear, 'value'),
      months_back:
        get(selectedMonthsBack, 'value') === 0
          ? null
          : get(selectedMonthsBack, 'value')
    }
  });

  const [selectedWork, setSelectedWork] = useState(null);
  const [studentWorks, setStudentWorks] = useState([]);
  const [isWorkDialogOpened, setIsWorkDialogOpened] = useState(false);

  const handleViewStrategyExamples = (event) => {
    const mostUsedStrategy = learningTrendsData.most_used_strategy;
    const studentWorks = learningTrendsData.most_used_strategy_works;
    const formattedStudentWorks = studentWorks.map((url) => ({
      imageUrl: url,
      category: mostUsedStrategy
    }));

    if (isEmpty(formattedStudentWorks)) return;

    event.preventDefault();
    setSelectedWork(first(formattedStudentWorks));
    setStudentWorks(formattedStudentWorks);
    setIsWorkDialogOpened(true);
  };

  const handleViewMisconceptionExamples = (event) => {
    const mostCommonMisconception =
      learningTrendsData.most_common_misconception;
    const studentWorks = learningTrendsData.most_common_misconception_works;
    const formattedStudentWorks = studentWorks.map((url) => ({
      imageUrl: url,
      category: mostCommonMisconception
    }));

    if (isEmpty(formattedStudentWorks)) return;

    event.preventDefault();
    setSelectedWork(first(formattedStudentWorks));
    setStudentWorks(formattedStudentWorks);
    setIsWorkDialogOpened(true);
  };

  return (
    <>
      <Paper className={styles.learningTrendsContainer} variant="outlined">
        {isWorkDialogOpened && (
          <StudentWorkViewDialog
            studentWork={selectedWork}
            setSelectedStudentWork={setSelectedWork}
            studentWorks={studentWorks}
            onClose={() => setIsWorkDialogOpened(false)}
          />
        )}
        <div className={styles.learningTrendsContent}>
          {/* Heading container row */}
          <div className={styles.headerContainer}>
            <Typography className={styles.title} variant="H-TEXT-2">
              learning trends
            </Typography>
          </div>

          {/* Content row */}
          <div className={styles.contentContainer}>
            {isLoading && (
              <div className={styles.loader}>
                <CircularProgress size={60} />
              </div>
            )}
            {!isLoading && (
              <ul>
                <li>
                  <Typography className={styles.title} variant="H-TEXT-3">
                    <b>class ranking:</b>&nbsp;
                    {learningTrendsData?.class_standing || 'N/A'}
                  </Typography>
                </li>
                <li className={styles.listItem}>
                  <Typography className={styles.title} variant="H-TEXT-3">
                    <b>most used strategy:</b>&nbsp;
                    {learningTrendsData?.most_used_strategy}
                  </Typography>

                  {!isEmpty(learningTrendsData?.most_used_strategy_works) && (
                    <div
                      onClick={handleViewStrategyExamples}
                      className={styles.inlineLink}
                      role="button"
                    >
                      <Typography className={styles.title} variant="H-TEXT-3">
                        view work
                        {learningTrendsData?.most_used_strategy_works.length ===
                        1
                          ? ' '
                          : `s `}
                        {`(${learningTrendsData?.most_used_strategy_works.length})`}
                      </Typography>
                    </div>
                  )}
                </li>
                <li className={styles.listItem}>
                  <Typography className={styles.title} variant="H-TEXT-3">
                    <b>most common error:</b>&nbsp;
                    {learningTrendsData?.most_common_misconception}
                  </Typography>

                  {!isEmpty(
                    learningTrendsData?.most_common_misconception_works
                  ) && (
                    <div
                      onClick={handleViewMisconceptionExamples}
                      className={styles.inlineLink}
                      role="button"
                    >
                      <Typography className={styles.title} variant="H-TEXT-3">
                        view work
                        {learningTrendsData?.most_common_misconception_works
                          .length === 1
                          ? ' '
                          : `s `}
                        {`(${learningTrendsData?.most_common_misconception_works.length})`}
                      </Typography>
                    </div>
                  )}
                </li>
              </ul>
            )}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default LearningTrends;
