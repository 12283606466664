import React, { useState } from 'react';

import { useMixpanelPageOpenTrack } from 'utils/integrations/mixpanel';

import CurriculumIndex from '../../../../components/CurriculumIndex';
import CurriculumNode from '../../../../components/CurriculumNode';
import AcceleratedCurriculumSwitch from './components/AcceleratedCurriculumSwitch';
import AssignmentCreateSelectFlow from './components/AssignmentCreateSelectFlow';
import CreateNewAssignment from './components/CreateNewAssignment';

import styles from './styles.module.scss';

const TeacherAssignmentCreate = () => {
  const [isFlowSelected, setIsFlowSelected] = useState(false);
  const [isCreateNewSelected, setIsCreateNewSelected] = useState(false);
  const [isCreateTemplateSelected, setIsCreateTemplateSelected] =
    useState(false);

  const [isAcceleratedCurriculum, setIsAcceleratedCurriculum] = useState(false);

  useMixpanelPageOpenTrack('Teacher opened Create page');

  const isThreeModalAssignmentCreationEnabled =
    process.env.REACT_APP_SHOW_THREE_MODAL_ASSIGNMENT_CREATION === 'true';

  return (
    <div className={styles.container}>
      {!isFlowSelected && (
        <AssignmentCreateSelectFlow
          setIsFlowSelected={setIsFlowSelected}
          setIsCreateNewSelected={setIsCreateNewSelected}
          setIsCreateTemplateSelected={setIsCreateTemplateSelected}
          isThreeModalAssignmentCreationEnabled={
            isThreeModalAssignmentCreationEnabled
          }
        />
      )}
      {!isThreeModalAssignmentCreationEnabled &&
        isFlowSelected &&
        isCreateNewSelected && <CreateNewAssignment />}
      {isFlowSelected && isCreateTemplateSelected && (
        <AcceleratedCurriculumSwitch
          isAcceleratedCurriculum={isAcceleratedCurriculum}
          setIsAcceleratedCurriculum={setIsAcceleratedCurriculum}
        />
      )}
      {isFlowSelected &&
        isCreateTemplateSelected &&
        (isThreeModalAssignmentCreationEnabled ? (
          <CurriculumIndex isAcceleratedCurriculum={isAcceleratedCurriculum} />
        ) : (
          <CurriculumNode isAcceleratedCurriculum={isAcceleratedCurriculum} />
        ))}
    </div>
  );
};

export default TeacherAssignmentCreate;
