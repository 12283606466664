import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  BottomNavigationAction,
  BottomNavigation as MuiBottomNavigation
} from '@material-ui/core';
import {
  PATHWAYS_COMMUNITY_URL,
  PATHWAYS_DAILY_PROGRESS_URL,
  PATHWAYS_PROGRESS_URL
} from 'config/urls';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';

import UserContext from 'components/UserContext';

import { ReactComponent as HomeIcon } from './assets/home.svg';
import { ReactComponent as ProgressIcon } from './assets/progress.svg';
import { ReactComponent as SpotlightIcon } from './assets/spotlight.svg';
import styles from './styles.module.scss';

const BottomNavigation = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const user = useContext(UserContext);

  useEffect(() => {
    if (
      history.location.pathname === PATHWAYS_DAILY_PROGRESS_URL &&
      activeTab !== 0
    ) {
      setActiveTab(0);
    }
    if (
      history.location.pathname === PATHWAYS_PROGRESS_URL &&
      activeTab !== 1
    ) {
      setActiveTab(1);
    }
    if (
      history.location.pathname === PATHWAYS_COMMUNITY_URL &&
      activeTab !== 2
    ) {
      setActiveTab(2);
    }
  }, [history, activeTab]);

  const handleChange = (_, newActiveTab) => {
    if (newActiveTab === 0) {
      window.location.href = PATHWAYS_DAILY_PROGRESS_URL;
    } else if (newActiveTab === 1) {
      window.location.href = PATHWAYS_PROGRESS_URL;
    } else if (newActiveTab === 2) {
      window.location.href = PATHWAYS_COMMUNITY_URL;
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
        {
          feature: 'pathways',
          view: 'community',
          button: 'visit community'
        }
      );
    } else {
      setActiveTab(newActiveTab);
    }
  };

  return (
    <MuiBottomNavigation
      className={styles.bottomNavigation}
      value={activeTab}
      onChange={handleChange}
      showLabels
    >
      <BottomNavigationAction
        classes={{
          root: styles.item,
          selected: activeTab === 0 && styles.itemSelected
        }}
        label="Home"
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
        classes={{
          root: styles.item,
          selected: activeTab === 1 && styles.itemSelected
        }}
        label="Progress"
        icon={<ProgressIcon />}
      />
      <BottomNavigationAction
        classes={{
          root: styles.item,
          selected: activeTab === 2 && styles.itemSelected
        }}
        label="Community"
        icon={<SpotlightIcon />}
      />
    </MuiBottomNavigation>
  );
};

export default BottomNavigation;
