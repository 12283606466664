import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import cx from 'classnames';
import {
  MOBILE_APP_URL,
  TEACHERS_BULK_UPLOAD_WORK_UPLOAD_AND_QR_URL,
  TEACHERS_BULK_UPLOAD_WORK_UPLOAD_URL
} from 'config/urls';
import scanImage from 'images/scan.png';
import _ from 'lodash';

import AddTeacherConfirmDialog from 'pages/Teachers/shared/AssignmentDialog/components/AssignAssignment/components/AddTeacherConfirmDialog';
import LinkMeetingDialog from 'pages/Teachers/shared/AssignmentDialog/components/AssignAssignment/components/LinkMeetingDialog';
import { colors } from 'theme/palette';
import { useDialog } from 'utils/hooks';
import { shareAssignmentToGoogleClassroom } from 'utils/integrations/googleClassroom';
import { notifyErrors } from 'utils/notifications';
import { openInNewTab } from 'utils/urls';

import AssignmentCreateDialogContext from 'components/AssignmentCreateDialogContext';
import Button from 'components/Button';
import Image from 'components/Image';
import Typography from 'components/Typography';

import { ReactComponent as GoogleClassroomIcon } from './assets/googleClassroom.svg';
import { addTeacherToSections, canPreviewAsStudent } from './sdk';
import styles from './styles.module.scss';

const AssignedAssignment = ({ assignmentData }) => {
  const history = useHistory();
  const {
    isOpened: confirmDialog,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog
  } = useDialog();
  const [studentPreview, setStudentPreview] = useState({
    loading: true,
    value: null
  });

  useEffect(() => {
    canPreviewAsStudent(assignmentData.id).then(({ data, success, errors }) => {
      if (success) {
        setStudentPreview({
          loading: false,
          value: data.can_preview_as_student
        });
      } else {
        setStudentPreview({ loading: false, value: null });
        notifyErrors(errors);
      }
    });
  }, [assignmentData.id]);

  const handleStudentPreview = useCallback(() => {
    if (_.isNull(studentPreview.value)) {
      return;
    }

    if (!studentPreview.value) {
      openConfirmDialog();
    } else {
      openInNewTab(assignmentData.student_deeplink);
    }
  }, [
    studentPreview.value,
    assignmentData.student_deeplink,
    openConfirmDialog
  ]);

  const ensureTeacherInSections = useCallback(() => {
    const { assignments, student_deeplink } = assignmentData;
    setStudentPreview((prev) => ({ ...prev, loading: true }));

    // Make sure the current user is teacher in the assignment sections.
    // He could be an admin for example which means that the demo student won't be part from the sections.
    // This makes sure that the teacher can preview the assignment from all assigned sections.
    const sectionIds = _.map(assignments, 'section_id');
    addTeacherToSections(sectionIds).then(() => {
      setStudentPreview({ loading: false, value: true });
      closeConfirmDialog();
      openInNewTab(student_deeplink);
    });
  }, [assignmentData, closeConfirmDialog]);

  const { closeDialog: closeAssignmentCreateDialog } = useContext(
    AssignmentCreateDialogContext
  );

  const {
    isOpened: isLinkMeetingDialogOpen,
    openDialog: openLinkMeetingDialog,
    closeDialog: closeLinkMeetingDialog
  } = useDialog();

  const handleLinkMeetingSuccess = useCallback(() => {
    closeLinkMeetingDialog();
    closeAssignmentCreateDialog();
  }, [closeLinkMeetingDialog, closeAssignmentCreateDialog]);

  const handleUploadPDFClick = useCallback(() => {
    history.push(
      process.env.REACT_APP_BULK_UPLOAD_NEW_UI_FOR_MATCH_STUDENTS_ENABLED ===
        'true'
        ? TEACHERS_BULK_UPLOAD_WORK_UPLOAD_AND_QR_URL
        : TEACHERS_BULK_UPLOAD_WORK_UPLOAD_URL
    );
    closeAssignmentCreateDialog();
  }, [history, closeAssignmentCreateDialog]);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <Grid
          container
          alignItems="center"
          direction="column"
          spacing={1}
          className={styles.header}
        >
          <Grid item>
            <Typography variant="H-TEXT-1" color={colors.pink2}>
              {assignmentData.name} created!
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="B-Text-1" color={colors.blue1}>
              Go Capture Student Work!
            </Typography>
          </Grid>
        </Grid>

        <div className={styles.boxesContainer}>
          <div className={styles.box}>
            <Typography
              variant="S-TEXT-1"
              color={colors.blue3}
              className={styles.boxTitle}
            >
              Add on to the Assignment
            </Typography>

            <>
              <Button
                color="blue"
                className={styles.button}
                onClick={openLinkMeetingDialog}
                startIcon={<TodayRoundedIcon />}
              >
                Link to Meeting
              </Button>
              <Button
                color="blue"
                className={styles.button}
                disabled={studentPreview.loading}
                onClick={handleStudentPreview}
              >
                {studentPreview.loading ? 'Loading...' : 'Add exemplar'}
              </Button>
            </>
            <Button
              color="grey"
              className={cx(styles.button, styles.transparent)}
              onClick={() =>
                shareAssignmentToGoogleClassroom({ assignment: assignmentData })
              }
              noTypography
            >
              <Typography
                variant="H-TEXT-3"
                className={styles.googleClassroomButtonContent}
              >
                <GoogleClassroomIcon /> Share to classroom
              </Typography>
            </Button>
          </div>
          <div
            className={cx(styles.box, styles.hoverable)}
            onClick={handleUploadPDFClick}
            data-intercom-target="Assignment Create Dialog Upload PDF Button"
          >
            <Typography
              variant="S-TEXT-1"
              color={colors.blue3}
              className={cx(styles.boxTitle, styles.reducedBottomMargin)}
            >
              Upload PDF
              <ArrowForwardIosIcon
                htmlColor={colors.blue3}
                fontSize="small"
                className={styles.boxTitleIcon}
              />
            </Typography>

            <Image src={scanImage} className={styles.scanImage} />

            <Typography
              variant="B-Text-3"
              className={styles.boxDescription}
              color={colors.blue1}
            >
              Scan Student Work and Our AI Matches Students to Pages
            </Typography>
          </div>
          <div className={styles.box}>
            <Typography
              variant="S-TEXT-1"
              color={colors.blue3}
              className={styles.boxTitle}
            >
              use scanning app
            </Typography>

            <QRCodeSVG
              width={106}
              height={106}
              value={`${window.location.origin}${MOBILE_APP_URL}`}
            />

            <Typography
              variant="B-Text-3"
              className={styles.boxDescription}
              color={colors.blue1}
            >
              Get EdLight’s Phone / Tablet App to Easily Capture Work
            </Typography>
          </div>
        </div>
      </div>
      {confirmDialog && (
        <AddTeacherConfirmDialog
          onClose={closeConfirmDialog}
          onConfirm={ensureTeacherInSections}
        />
      )}
      {isLinkMeetingDialogOpen && (
        <LinkMeetingDialog
          onClose={closeLinkMeetingDialog}
          onLinkMeetingSuccess={handleLinkMeetingSuccess}
          assignedSections={assignmentData.assignments}
          assignmentName={assignmentData.name}
        />
      )}
    </React.Fragment>
  );
};

export default AssignedAssignment;
