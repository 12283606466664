import React from 'react';

import {
  matchColortoIcon,
  remappedRubricsToChecks
} from 'pages/Pathways/utils';

import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const InfoRubricDialog = ({ rubrics, onClose }) => (
  <Dialog
    open={true}
    onClose={onClose}
    classes={{ paper: styles.dialogStyles }}
  >
    <div className={styles.rubricListContainer}>
      {rubrics.map(({ rubric_name, description, value }, index) => (
        <Typography
          component="div"
          variant="B-Text-1"
          className={styles.tooltipText}
          key={`rubric-item-${index}`}
        >
          <div className={styles.dialogData}>
            <span className={styles.dialogText}>
              {remappedRubricsToChecks({
                masteryLevel: value,
                color: matchColortoIcon({ valueIcon: value })
              })}
            </span>{' '}
            <span>{rubric_name}</span>
          </div>
        </Typography>
      ))}
    </div>
  </Dialog>
);

export default InfoRubricDialog;
