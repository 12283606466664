import React, { useContext, useState } from 'react';

import _ from 'lodash';
import { useAssignmentsNamesList } from 'sdk';

import { SearchField } from 'pages/Teachers/shared';
import { AssignmentDialogModes } from 'pages/Teachers/shared/AssignmentDialog';
import { colors } from 'theme/palette';
import { MuiAutocompleteChangeReasons } from 'utils/constants';

import AddButton from 'components/AddButton';
import AssignmentCreateDialogContext from 'components/AssignmentCreateDialogContext';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const CreateNewAssignment = () => {
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const { open: openCreateAssignmentDialog } = useContext(
    AssignmentCreateDialogContext
  );
  const { data: assignmentsNames } = useAssignmentsNamesList({
    data: { include_prior_years: true }
  });

  const handleAssignmentChange = (event, value, reason) => {
    if (_.isNil(value)) {
      return;
    }

    setSelectedAssignment(value);

    if (reason === MuiAutocompleteChangeReasons.SELECT_OPTION) {
      openCreateAssignmentDialog({
        mode: AssignmentDialogModes.COPY,
        selectedAssignment: value
      });
    } else {
      openCreateAssignmentDialog({
        mode: AssignmentDialogModes.CREATE,
        selectedAssignment: value
      });
    }
  };

  return (
    <>
      <AddButton onClick={openCreateAssignmentDialog} color="pink">
        Create a new assignment
      </AddButton>
      <div className={styles.wrapper}>
        <Typography variant="H-TEXT-2" color={colors.blue1} align="center">
          Copy an assignment
        </Typography>
        <Typography
          variant="B-Text-2"
          color={colors.grey1}
          align="center"
          className={styles.description}
        >
          Modify a copy of an assignment you&apos;ve already made.
        </Typography>

        <SearchField
          value={selectedAssignment}
          options={assignmentsNames || []}
          getOptionLabel={(assignment) => assignment.name}
          onChange={handleAssignmentChange}
          nooptionstext="No Assignments"
          placeholder="Search your assignments"
        />
      </div>
    </>
  );
};

export default CreateNewAssignment;
