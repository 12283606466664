import React from 'react';
import { useHistory } from 'react-router-dom';

import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { PATHWAYS_DAILY_PROGRESS_URL } from 'config/urls';
import { get, round } from 'lodash';
import { pathwaysJourneyCompleteStartTrackingGoals } from 'sdk';
import { usePathwaysResults } from 'sdk';

import { notifyError } from 'utils/notifications';

import ResultsAccordion from './components/ResultsAccordion';
import ResultsChart from './components/ResultsChart';
import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const PathwaysResults = () => {
  const { data: results } = usePathwaysResults();
  const history = useHistory();

  const remappedResults = [
    {
      subject: 'Teacher Knowledge & Skill',
      result: get(results, 'teacher_knowledge_and_skill_result', 0),
      maxResult: get(results, 'max_teacher_knowledge_and_skill_result', 0),
      percentage: round(
        get(results, 'teacher_knowledge_and_skill_result', 0) /
          get(results, 'max_teacher_knowledge_and_skill_result', 0),
        2
      )
    },
    {
      subject: 'Student Engagement',
      result: get(results, 'student_engagement_result', 0),
      maxResult: get(results, 'max_student_engagement_result', 0),
      percentage: round(
        get(results, 'student_engagement_result', 0) /
          get(results, 'max_student_engagement_result', 0),
        2
      )
    },
    {
      subject: 'Content Rigor & Relevance',
      result: get(results, 'content_rigor_and_relevance_result', 0),
      maxResult: get(results, 'max_content_rigor_and_relevance_result', 0),
      percentage: round(
        get(results, 'content_rigor_and_relevance_result', 0) /
          get(results, 'max_content_rigor_and_relevance_result', 0),
        2
      )
    }
  ];

  const onStartTrackingYourGoalsClick = async () => {
    const { success } = await pathwaysJourneyCompleteStartTrackingGoals();

    if (success) {
      history.push(PATHWAYS_DAILY_PROGRESS_URL);
    } else {
      notifyError('Could not set start tracking goals in the user journey');
    }
  };

  return (
    <div className={styles.resultsContainer}>
      <div className={styles.scrollableContainer}>
        <Typography variant="H-TEXT-1" className={styles.resultsAreReady}>
          Your Results are Ready!
        </Typography>

        <ResultsChart results={remappedResults} />

        <ResultsAccordion results={remappedResults} />
      </div>

      <div className={styles.bottomButton}>
        <Button
          variant="textAndIcon"
          className={styles.startButton}
          endIcon={<ArrowForwardIosOutlinedIcon className={styles.icon} />}
          onClick={onStartTrackingYourGoalsClick}
        >
          Start Tracking Your Goals
        </Button>
      </div>
    </div>
  );
};

export default PathwaysResults;
