import React, { useContext, useEffect, useState } from 'react';

import MobileStepper from '@material-ui/core/MobileStepper';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import {
  MixpanelAction,
  MixpanelObject
} from 'utils/integrations/mixpanel/constants';

import ForwardButton from 'components/ForwardButton';
import Swipeable from 'components/Swipeable';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import CommunityImage from './assets/community.png';
import ElevateImage from './assets/elevate.png';
import EmpowerImage from './assets/empower.png';
import styles from './styles.module.scss';

const SwiperCarousel = ({ onSwipeFinished }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(() => {
        if (currentIndex > 1) {
          clearInterval(timer);
          return currentIndex;
        }

        return currentIndex + 1;
      });
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [currentIndex]);

  const slides = [
    {
      id: 1,
      title: 'Empower Your Teaching',
      content: 'Set goals, get tailored guidance, and see real progress.',
      image: EmpowerImage,
      buttonText: 'Next'
    },
    {
      id: 2,
      title: 'Elevate Your Professional Growth',
      content:
        'Real-time classroom insights & continuous feedback for every teacher.',
      image: ElevateImage,
      buttonText: 'Next'
    },
    {
      id: 3,
      title: 'Join a Supportive Community',
      content: 'Share, learn, and grow with other teachers.',
      image: CommunityImage,
      buttonText: 'Get started'
    }
  ];

  const handleNext = () => {
    const currentSlide = slides[currentIndex];

    if (currentSlide.buttonText === 'Get started') {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
        {
          feature: 'pathways',
          view: 'splash',
          button: 'get started'
        }
      );
    }

    if (currentSlide.buttonText === 'Next') {
      trackMixpanelEvent(
        user.id,
        `${MixpanelObject.BUTTON} ${MixpanelAction.CLICK}`,
        {
          feature: 'pathways',
          view: 'splash',
          button: 'next'
        }
      );
    }

    if (currentIndex === slides.length - 1) {
      onSwipeFinished();
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <div className={styles.swiperContainer}>
      <Swipeable
        onSwipedLeft={handleNext}
        onSwipedRight={handlePrev}
        preventDefaultTouchmoveEvent={true}
        trackMouse={true}
      >
        <div
          className={styles.swiperWrapper}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.map((item) => (
            <div key={item.id} className={styles.swiperSlide}>
              <img
                src={item.image}
                alt={item.title}
                className={styles.carouselImage}
              />
              <MobileStepper
                variant="dots"
                steps={3}
                position="static"
                activeStep={currentIndex}
                classes={{
                  dot: styles.customDot,
                  dotActive: styles.dotActive,
                  root: styles.stepperRoot
                }}
              />
              <div className={styles.textWrapper}>
                <Typography variant="H-TEXT-3" className={styles.carouselTitle}>
                  {item.title}
                </Typography>
                <Typography
                  variant="B-Text-3"
                  className={styles.carouselContent}
                >
                  {item.content}
                </Typography>
              </div>
              <ForwardButton
                color="pink"
                className={styles.carouselButton}
                onClick={handleNext}
              >
                {item.buttonText}
              </ForwardButton>
            </div>
          ))}
        </div>
      </Swipeable>
    </div>
  );
};

export default SwiperCarousel;
