import React, { useMemo, useState } from 'react';

import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';
import RotateRightOutlinedIcon from '@material-ui/icons/RotateRightOutlined';
import { first, isEmpty, map } from 'lodash';

import StudentWorkViewDialog from 'components/StudentWorkViewDialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const WhereToGrow = ({
  studentAssignmentsAssignedCount,
  classAssignmentsAssignedAverageCount,
  studentConceptsToReviewCount,
  classConceptsToReviewAverageCount,
  studentConceptsToReviewWorks
}) => {
  const [selectedWork, setSelectedWork] = useState(null);
  const [isWorkDialogOpened, setIsWorkDialogOpened] = useState(false);

  const studentWorks = useMemo(() => {
    return map(studentConceptsToReviewWorks, (url) => {
      return {
        imageUrl: url,
        category: 'Concepts To Review'
      };
    });
  }, [studentConceptsToReviewWorks]);

  const handleViewWhereToGrow = (event) => {
    if (isEmpty(studentWorks)) return;

    event.preventDefault();
    setSelectedWork(first(studentWorks));
    setIsWorkDialogOpened(true);
  };

  return (
    <div className={styles.whereToGrowSectionOuter}>
      {isWorkDialogOpened && (
        <StudentWorkViewDialog
          studentWork={selectedWork}
          setSelectedStudentWork={setSelectedWork}
          studentWorks={studentWorks}
          onClose={() => setIsWorkDialogOpened(false)}
        />
      )}
      <Typography className={styles.sectionTitle} variant="H-TEXT-2">
        where to grow
      </Typography>
      <div
        className={styles.whereToGrowSectionInner}
        role="button"
        onClick={handleViewWhereToGrow}
      >
        <div className={styles.growDataAssignmentsAssigned}>
          <div className={styles.strength}>
            <div className={styles.spacer}></div>
            <div className={styles.assignmentsNumber}>
              {studentAssignmentsAssignedCount}
            </div>
            <div>
              <AddAlertOutlinedIcon className={styles.bellIcon} />
            </div>
            <div className={styles.spacer}></div>
          </div>
          <div className={styles.strengthText}>
            <Typography className={styles.innerText}>
              ASSIGNMENTS ASSIGNED
            </Typography>
          </div>
        </div>
        <div className={styles.growDataWhereToGrow}>
          <div className={styles.strength}>
            <div className={styles.spacer}></div>
            <div className={styles.conceptsNumber}>
              {studentConceptsToReviewCount}
            </div>
            <div>
              <RotateRightOutlinedIcon className={styles.progressIcon} />
            </div>
            <div className={styles.spacer}></div>
          </div>
          <div className={styles.strengthText}>
            <Typography className={styles.innerText}>
              CONCEPTS TO REVIEW
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.averageContainer}>
        <Typography variant="H-TEXT-3">
          average: {classAssignmentsAssignedAverageCount}
        </Typography>
        <Typography variant="H-TEXT-3">
          average: {classConceptsToReviewAverageCount}
        </Typography>
      </div>
    </div>
  );
};

export default WhereToGrow;
