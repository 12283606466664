import { matchPath } from 'react-router';

import {
  FAMILY_PLANNER_URL,
  FAMILY_URL,
  PATHWAYS_START_URL,
  STUDENTS_ASSIGNMENTS_LIST_URL,
  STUDENTS_URL,
  TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL
} from 'config/urls';
import _ from 'lodash';

import {
  shutdownIntercomWidget,
  updateIntercomEntity
} from 'utils/integrations/intercom';

export const getRedirectPath = ({ user, nextPath, isMobile = false }) => {
  const isTeacher = user.is_teacher || user.is_superuser;
  const isStudent = !_.isNull(user.student_id);
  const isPathwaysUser = user.is_pathways_user;
  const isFamilyMember = user.is_family_member;

  const studentAppMatch = matchPath(nextPath, {
    path: STUDENTS_URL,
    exact: false
  });

  const familyAppMatch = matchPath(nextPath, {
    path: FAMILY_URL,
    exact: false
  });

  if (isTeacher && isPathwaysUser) {
    return PATHWAYS_START_URL;
  }

  if (nextPath) {
    if (isTeacher && !studentAppMatch && !familyAppMatch) {
      // If there is next path & the user is a teacher trying to access a /teacher route -> redirect to the next path.
      return nextPath;
    }

    if (isStudent && studentAppMatch) {
      // If there is next path & the user is a student trying to access a /student route -> redirect to the next path.
      return nextPath;
    }

    if (isFamilyMember && familyAppMatch) {
      // If there is next path & the user is a family member trying to access a /family route -> redirect to the next path.
      return nextPath;
    }

    if (isTeacher && !isStudent && studentAppMatch) {
      // If there is next path & the user is a teacher, but without a demo student, trying to access a /student route -> redirect to the teachers' homepage.
      return TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL;
    }

    if (isTeacher && !isFamilyMember && familyAppMatch) {
      // If there is next path & the user is a teacher, but without a demo student, trying to access a /family route -> redirect to the teachers' homepage.
      return TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL;
    }

    if (isStudent && !isTeacher && !studentAppMatch) {
      // If there is next path & the user is a regular (non-teacher-demo) student, trying to access a /teacher route -> redirect to the students' homepage.
      return STUDENTS_ASSIGNMENTS_LIST_URL;
    }

    if (isFamilyMember && !isTeacher && !familyAppMatch) {
      // If there is next path & the user is a regular (non-teacher-demo) family member, trying to access a non-/family route -> redirect to the family' homepage.
      return FAMILY_PLANNER_URL;
    }
  }

  // If there is no next path -> redirect to the respective user's homepage.
  if (isTeacher) {
    return TEACHERS_INSIGHTS_V2_ASSIGNMENTS_URL;
  }
  if (isFamilyMember) {
    return FAMILY_PLANNER_URL;
  }
  return STUDENTS_ASSIGNMENTS_LIST_URL;
};

export const handleIntercomOnRedirect = (user, redirectPath) => {
  const isTeacher = user.is_teacher || user.is_superuser;
  const studentAppMatch = matchPath(redirectPath, {
    path: STUDENTS_URL,
    exact: false
  });

  if (isTeacher) {
    updateIntercomEntity(user);
  }

  if (studentAppMatch) {
    // Make sure to hide the Intercom widget if the user is being redirected to the students' app.
    shutdownIntercomWidget();
  }
};
